<template>
    <div id="nav">
      <div class="mx-auto navbar-logo" style="width: 230px; cursor: pointer">
        <img
          src="../assets/logo_up_bar_2024.png"
          @click="redirectHome"
          width="230"
          alt="Logo"
          class="center"
          style="margin-left: 20px;"
        />
      </div>
      <div class="navbar-buttons">
        <div v-if="isLoggedIn">
          <div class="menu-toggle" @click="toggleMenu">
            <img src="../assets/ic-menu.png" alt="Menu" />
          </div>
          <div
            class="menu-overlay"
            :class="{ open: isMenuOpen }"
            @click="toggleMenu"
          ></div>
          <div class="menu-collapse" :class="{ open: isMenuOpen }">
            <ul>
              <li>
                  <button class="btn" @click="home">
                      <font-awesome-icon
                              :icon="['fas', 'house']"
                              style="color: #666666"
                      />
                      Home
                  </button>
              </li>
              <li>
                <button class="btn" @click="historic">
                  <font-awesome-icon
                    :icon="['fas', 'book-medical']"
                    style="color: #666666"
                  />
                  Histórico
                </button>
              </li>
              <li>
                  <button class="btn" @click="suporteWhatsApp">
                      <font-awesome-icon :icon="['fab', 'whatsapp']" style="color: #666" />
                      Suporte
                  </button>
              </li>
              <li>
                <button class="btn" @click="logout">
                  <font-awesome-icon
                    :icon="['fasr', 'arrow-right-from-bracket']"
                    style="color: #666666"
                  />
                  Sair
                </button>
              </li>
              <li>
                <p style="margin-left: 15px; font-size: 13px; color: #666666;">Versão {{ this.$store.state.version }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <button class="btn btn-logout" @click="logout">Entrar</button>
        </div>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: "NavBar",
    data() {
      return {
        isMenuOpen: false,
      };
    },
    computed: {
      isLoggedIn: function () {
        return true//this.$store.getters.isAuthenticated;
      },
    },
    methods: {
      async logout() {
        await this.$store.dispatch("LogOut");
        this.$router.push("/login");
      },
      async home() {
        this.$router.push("/");
      },
      async historic() {
        this.$router.push("/historymenu");
      },
      suporteWhatsApp() {
          const whatsappUrl = "https://api.whatsapp.com/send/?phone=551151998013&text=Ol%C3%A1,+preciso+de+suporte+na+minha+jornada";
          window.open(whatsappUrl, "_blank");
      },
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
        if (this.isMenuOpen) {
          this.$nextTick(() => {
            document.addEventListener("click", this.closeMenuOnClickOutside);
          });
        } else {
          document.removeEventListener("click", this.closeMenuOnClickOutside);
        }
      },
      closeMenuOnClickOutside(event) {
        if (!this.$el.contains(event.target)) {
          this.isMenuOpen = false;
          document.removeEventListener("click", this.closeMenuOnClickOutside);
        }
      },
      redirectHome() {
        this.$router.push("/");
      },
    },
  };
  </script>

  <style>
  #nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    padding: 0;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }

  .navbar-logo img {
    height: 50px;
  }

  .navbar-buttons button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #fff;
    color: #666666;
    font-size: 13px;
    cursor: pointer;
  }

  .navbar-buttons button:hover {
    background-color: #fff;
  }

  .menu-toggle {
    display: block;
    cursor: pointer;
    width: 30px;
    height: 20px;
    position: relative;
    margin-right: 30px;
  }

  .menu-toggle span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #333;
    transition: all 0.3s ease-in-out;
  }

  .menu-toggle span:nth-child(1),
  .menu-toggle span:nth-child(3) {
    top: 0;
  }

  .menu-toggle span:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }

  .menu-overlay {
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    transition: opacity 0.3s ease-in-out;
  }

  .menu-overlay.open {
    display: block;
    opacity: 1;
  }

  .menu-collapse {
    display: none;
    position: fixed;
    margin-top: 78px;
    top: 0;
    right: 0;
    width: 200px;
    background-color: #fff;
    z-index: 2;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.5);
  }

  .menu-collapse.open {
    display: block;
    transform: translateX(0);
  }

  .menu-collapse ul {
    list-style: none;
    margin-top: 10px;
    padding: 0;
  }

  .menu-collapse ul li {
    margin-bottom: 10px;
  }

  .menu-collapse ul li a {
    text-decoration: none;
    color: #333;
  }
  </style>

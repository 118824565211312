export default {
    isAuthenticated(state) {
        const user = localStorage.getItem('user-flow');
        if (user !== null) {
            state.user = JSON.parse(user);
            return state.user;
        }
        return null;
    },
    isSpecialty(state) {
        return state.specialty;
    },
    StateDailyCases: (state) => state.dailyCases,
    StateScheduleDailyCases: (state) => state.scheduleDailyCases,
    StateEvolutionCases: (state) => state.evolutionCases,
    StateGeneralCases: (state) => state.generalCases,
    StateEvolvedPatientsToday: (state) => state.evolvedPatientsToday,
    StatePatient: (state) => state.patient,
    StatePatientMother: (state) => state.patientMother,
    StateProtocol: (state) => state.protocol,
    StateChildrens: (state) => state.childrens,
    StateResponsible: (state) => state.responsible,
    StateOutcomes: (state) => state.outcome,
    StateUser: (state) => state.user,
    StateReports: (state) => state.reports,
    StateReport: (state) => state.report,
    StateSpecialty: (state) => state.specialty,
    StateUrlReport: (state) => state.urlReport,
}

<template>
    <div class="container">
        <div v-if="isLoading === true">
            <div class="containermb-5">
                <div class="row">
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8">
                            <div class="centered-container">
                                <div class="loading"></div>
                                <p class="text-font" style="margin-top: 10px; text-align: center">
                                    Carregando...
                                </p>
                            </div>
                        </div>
                        <div class="col-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="row mt-3">
                <div class="col-2"></div>
                <div class="col-8 d-flex justify-content-center">
                    <button class="add-paciente-btn" @click="redirectForm()">+ ADICIONAR PACIENTE</button>
                </div>
                <div class="col-2"></div>
            </div>            
            <div v-if="ScheduleDailyCases.length > 0">
                <div class="row mt-5">
                    <h4 class="text-font-bold">
                        CONSULTAS AGENDADAS:
                    </h4>
                </div>
                <div v-for="(dailyCase, index) in ScheduleDailyCases" :key="dailyCase.id" class="row mt-3"
                    style="cursor: pointer">
                    <div style="display: flex; align-items: center;" class="border-in">
                        <div class="circle">
                            {{ index + 1 }}
                        </div>
                        <div style="display: flex; flex-direction: column; margin-left: 10px;">
                            <h5 class="text-font" style="margin: 0;">Paciente: {{ dailyCase.protocol_info.patient_info.name }}</h5>
                            <p class="text-font" style="margin: 0;">Número de protocolo: {{ dailyCase.protocol_info.number }}</p>
                            <p class="text-font" style="margin: 0;">Especialidade: {{ dailyCase.protocol_info.specialty }}</p>
                            <p class="text-font" style="margin: 0;">Admissão: {{ dailyCase.protocol_info.created_at }}</p>                            
                        </div>
                    </div>
                </div>
            </div>                    
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: `ScheduleDailyRoutine`,
    components: {},
    computed: {
        ...mapGetters({ ScheduleDailyCases: "StateScheduleDailyCases", User: "StateUser"}),
    },
    data() {
        return {
            isLoading: true,
        };
    },
    async created() {        
        if (!this.isAuthenticated()) {
            this.$router.push("/login");
        }
        else if (!this.isSpecialty()) {
            this.$router.push("/");
        }
        else {
            await this.RefreshListCase();            
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions(["GetScheduleDailyCases"]),        
        ...mapActions(["CloseProtocol"]),
        ...mapGetters(["isAuthenticated"]),
        ...mapGetters(["isSpecialty"]),
        redirectForm() {
            this.$router.push("/form");
        },                   
        async RefreshListCase() {            
            await this.GetScheduleDailyCases();
        }
    }
};
</script>

<style scoped>
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.border-in {
    border-bottom: 2px solid #E4E4E4;
    padding-bottom: 10px;
}

.no-background {
    background-color: transparent;
    border: none;
    color: #6200EE;
    cursor: pointer;
    display: inline-block;
}

.text-right {
    display: block;
    text-align: right;
}

.no-background:hover {
    text-decoration: underline;
}

.circle {
    width: 50px;
    height: 50px;
    background-color: #EE3C4E;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
}

.add-paciente-btn {
    background-color: white;
    border: 2px solid #6200EE;
    color: #6200EE;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.text-font-bold {
    color: #777777;
    font-weight: bold;
}

.text-font {
    color: #777777;
}

.custom-select {
    border: 2px solid #6200EE;
    border-radius: 25px;
    padding: 5px;
    font-size: 16px;
    width: 250px;
    color: #6200EE;
    background-color: white;
}

.custom-select option {
    text-align: center;
}

.custom-select:focus {
    outline: none;
    border-color: #6200EE;
}
</style>
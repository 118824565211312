export default {
    setUser(state, user) {
        state.user = user
        localStorage.setItem('user-flow', JSON.stringify(user));
    },

    setSpecialty(state, specialty) {
        state.specialty = specialty;        
    },

    logout(state, user) {
        state.user = user;
        localStorage.removeItem('user-flow');
    },

    setDailyCases(state, dailyCases) {
        state.dailyCases = dailyCases;
    },

    setScheduleDailyCases(state, scheduleDailyCases) {
        state.scheduleDailyCases = scheduleDailyCases;
    },

    setEvolutionCases(state, evolutionCases) {
        state.evolutionCases = evolutionCases;
    },

    setDailyGeneralCases(state, generalCases) {
        state.generalCases = generalCases;
    },

    setEvolvedPatientsToday(state, evolvedPatientsToday){
        state.evolvedPatientsToday = evolvedPatientsToday;
    },

    setProtocol(state, protocol) {
        state.protocol = protocol;
    },

    setChildren(state, childrens) {
        state.childrens = childrens;
    },

    setPatient(state, patient) {
        state.patient = patient;
    },

    setPatientMother(state, patientMother) {
        state.patientMother = patientMother;
    },

    setResponsible(state, responsible) {
        state.responsible = responsible;
    },

    setOutcome(state, outcome) {
        state.outcome = outcome;
    },

    setReport(state, report) {
        state.report = report;
    },

    setUrlReport(state, urlReport) {
        state.urlReport = urlReport;
    },

    GetReport(state, GetReport) {
        state.GetReport = GetReport;
    },
}
